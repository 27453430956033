export enum StorageItemType {
    USER_ID = 'userid',
    REFRESH_TOKEN = 'refreshToken',
    MOBILE_NUMBER = 'mobileNumber',
    TOKEN = 'token',
    USER_PROFILE = 'userprofile',
    USER_PERMISSIONS = 'userpermissions',
    GARAGE_ID = "garageId",
    IS_ADMIN = 'isAdminLogIn',
    ADMIN_LOGIN_ID = 'adminLoginId'
}

export enum SnackBar {
    SUCCESS = 'success-message',
    ERROR = 'error-message'
}

export enum ContainerName {
    VEHICLE_DOCUMENT = 'vehicledocuments',
    VARIANTS = 'variants',
    BRANDS = 'brands',
    USER_PROFILES = 'userprofiles',
    GARAGE_DOCUMENT = 'garagedocuments',
    SERVICING = 'servicing',
    BANNER = 'banner',
    JOB_CARD = 'jobcard'
}

export enum FileType {
    JPG = 'jpg',
    JPEG = 'jpeg',
    PNG = 'png',
    PDF = 'pdf',
    APPLICATION_PDF = 'application/pdf',
    HEIC = 'heic',
    HEIF = 'heif',
    Image_JPEG = 'image/jpeg'
}

export enum DocType {
    POLICY_DOCUMENT = 'policyDocumnet',
    PUC_DOCUMENT = 'pucDocument',
    RC_BOOK = 'rcBook',
    INSURANCE = 'insurance'
}

export enum MenuCode {

    GARAGE = "GARREG",
    ACCOUNTSETTING  = "ACCTSET",
    GARAGESETTING = "GARSET",
    ONGOINGSERVICE = "ONGSERV",
    SERVICEHISTORY = "SERHIS",
    SERVICEINVOICE = "SERVIN",
    USERREGISTRATION = "USRREG",
    MYVEHICLE = "MYVHCL",
    BOOKSERVICE = "BKSRV",
    ADMIN = "ADMIN",
    SERVICEDETAILS = "SDETALS",
    JOBCARD = "JBCARD",
    GARAGEOWNER = "GRO"

}

export enum DatePattern{
    DDMMYYYY ='dd-MM-yyyy',
    DDMMMMEEE = 'dd MMMM EEE',
    DATE_TIME = 'dd-MM-yyyy h:mm:ss'
}

export enum OngoingServiceTab {
    NEW_SERVICE = 'NEW_SERVICE',
    UPCOMING_REQUEST = 'UPCOMING_REQUEST',
    ONGOING_SERVICE = 'ONGOING_SERVICE',
    COMPLETE= 'COMPLETE',
    REJECTED = 'REJECTED',
    CANCELLED ='CANCELLED',
    EXPIRE_SERVICE = 'EXPIRE_SERVICE'
}

export enum ServiceTypeCode {
    IN_STORE = 'INST',
    PICK_AND_DROP = 'PICK',
    DOOR_STEP = 'DOOR'
}

export enum invoiceStatusCode {
    SUBMIT = 'SUBMIT',
    DRAFT = 'DRAFT'
}