import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { environments } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  isOnline = true;

  constructor(public translate: TranslateService, private router: Router) {
    // translate.addLangs(['en', 'hi','gu','ma']);
    // translate.setDefaultLang('en');
    this.isOnline = navigator.onLine;
    // this.findLocation();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Push page view event to GTM data layer
      window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({
        event: 'page_view',
        page_path: event.urlAfterRedirects
      });
    });
  }


  findLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => this.processLocation(position.coords.latitude, position.coords.longitude),
        (error:any) => this.handleError('Error getting location', error)
      );
    } else {
      this.setOfflineStatus();
    }
  }

  private async processLocation(lat: number, lng: number): Promise<void> {
    try {
      const countryCode = await this.fetchCountryCode(lat, lng);
      this.isOnline = countryCode === 'IN';
    } catch (error) {
      this.handleError('Error fetching country data', error);
    }
  }

  private async fetchCountryCode(lat: number, lng: number): Promise<string> {
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${environments.googleAPIkey}`;

    const response = await fetch(geocodingUrl);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (data.status === 'OK' && data.results.length > 0) {
      const countryComponent = data.results[0].address_components.find((component: any) =>
        component.types.includes('country')
      );

      return countryComponent?.short_name ?? '';
    }

    throw new Error('No country data found');
  }

  private handleError(message: string, error: Error): void {
    console.error(`${message}:`, error);
    this.setOfflineStatus();
  }

  private setOfflineStatus(): void {
    this.isOnline = false;
  }

  retry() {
    window.location.reload();
  }
}
